<template>
  <div>
    <slot :cashback="cashback" :min-order="minOrder" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Number,
    officeType: {
      type: String,
      required: true,
    },
    areaCode: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    shipmentType: String,
  },
  emits: ['input'],
  computed: {
    ...mapGetters({
      officeCategories: 'office_categories/getAvailableOfficeCategories',
    }),
    cashback: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    minOrder() {
      return this.officeCategory.attributes[
        this.areaCode === 0 ? 'min_order_jawa' : 'min_order_luar_jawa'
      ];
    },
    officeCategory() {
      const officeCategoryName = {
        pusat: 'Pusat',
        kantor_pelayanan: 'Kantor Pelayanan',
      }[this.officeType];

      return this.officeCategories.find(
        (officeCategory) =>
          officeCategory.attributes.name === officeCategoryName
      );
    },
  },
  watch: {
    totalPrice: {
      immediate: true,
      handler() {
        this.setCashback();
      },
    },
  },
  methods: {
    setCashback() {
      if (this.shipmentType === 'delivery') {
        this.cashback = 0;
      } else {
        const cashback =
          this.officeCategory.attributes[
            this.areaCode === 0 ? 'cashback_jawa' : 'cashback_luar_jawa'
          ];

        const cashbackMultiplier = Math.floor(this.totalPrice / this.minOrder);

        this.cashback = cashbackMultiplier * cashback;
      }
    },
  },
};
</script>
