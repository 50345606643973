var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('create-order-form',{attrs:{"with-destination-area":"","restock-type-label":"Metode Penjualan","with-loan":false,"with-order-balance":"","disabled-date":false},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.order.destinationOffice.id)?_c('cashback-order-provider',{staticClass:"space-y-4",attrs:{"office-type":"pusat","total-price":_vm.totalPrice,"area-code":_vm.order.area.code,"shipment-type":_vm.order.shipment_type},scopedSlots:_vm._u([{key:"default",fn:function({ minOrder: minCashback }){return [_c('create-order-detail-form',{attrs:{"order-id":_vm.order.id,"origin-office":{ id: _vm.me.office_id, code: _vm.me.office_code },"origin-warehouse":_vm.order.originWarehouse,"destination-office":_vm.order.destinationOffice,"buyer-type":_vm.order.buyerType,"area":_vm.order.area,"min-spend":_vm.minOrderSpend,"with-cashback-warning":"","min-cashback":minCashback,"columns":[
          'code',
          'name',
          'point',
          'stock',
          'qty',
          'price',
          'total_price',
          'action',
          'total_weight',
          'point_value',
          'bonus_value',
        ],"total-price-label":"","create-order-attributes":{
          is_using_stock: _vm.order.using_stock,
          order_shipment: _vm.order.shipment_type,
          order_type: 'sale',
          destination_warehouse_id:
            _vm.order.destinationOffice.currentWarehouse.id,
          ...(_vm.order.custom_shipment_address
            ? {
                destination_address: {
                  province_id: _vm.order.shipmentAddress.province.id,
                  city_id: _vm.order.shipmentAddress.city.id,
                  district_id: _vm.order.shipmentAddress.district.id,
                  village_id: _vm.order.shipmentAddress.village.id,
                  address: _vm.order.shipmentAddress.address,
                },
              }
            : {}),
        }},on:{"order-created":_vm.onOrderCreated},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}),(_vm.totalPrice > 0)?[_c('base-card',[_c('div',{staticClass:"grid grid-cols-2 items-start gap-4"},[_c('div',{staticClass:"grid gap-4"},[_c('base-input',{attrs:{"inset":"","disabled":"","label":"Total Barang","value":_vm._f("toCurrency")(_vm.totalQty)}}),_c('base-input',{attrs:{"inset":"","disabled":"","label":"Cashback","value":_vm._f("toCurrency")(_vm.cashback)}})],1),_c('div',{staticClass:"grid gap-4"},[_c('base-input',{attrs:{"inset":"","disabled":"","label":"DPP","value":_vm._f("toCurrency")(_vm.dpp)}}),_c('base-input',{attrs:{"inset":"","disabled":"","label":"PPN (%)","value":_vm._f("toCurrency")(_vm.ppn)}}),_c('base-input',{attrs:{"inset":"","disabled":"","label":"Total Belanja","value":_vm._f("toCurrency")(_vm.totalPrice)}}),_c('base-input',{attrs:{"inset":"","disabled":"","label":_vm.paymentRemainder > 0 ? 'Total Kekurangan' : 'Total Kelebihan',"value":_vm._f("toCurrency")(Math.abs(_vm.paymentRemainder))}})],1)])]),_c('create-payment-form',{attrs:{"order-id":_vm.order.id,"payment-id":_vm.paymentId,"auto-save":"","sync":"","filter-reserved":false,"filter-payment-method-type":{
            'filter[not-name]': 'Cashback'
          },"stockist-balance":_vm.order.destinationOffice.order_balance,"with-validate-button":false,"with-summary":false},model:{value:(_vm.paymentMethods),callback:function ($$v) {_vm.paymentMethods=$$v},expression:"paymentMethods"}})]:_vm._e(),_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-button',{attrs:{"disabled":_vm.totalPrice < _vm.minOrderSpend},on:{"click":function($event){_vm.visibleValidate = true}}},[_vm._v("Siap Validasi")]),_c('base-button',{attrs:{"color":"danger","to":{ name: 'penjualan' }}},[_vm._v("Selesai")])],1)]}}],null,false,3703209610),model:{value:(_vm.cashback),callback:function ($$v) {_vm.cashback=$$v},expression:"cashback"}}):_vm._e()],1),_c('validate-order-confirmation',{attrs:{"order-id":_vm.order.id,"visible":_vm.visibleValidate},on:{"close":function($event){_vm.visibleValidate = false},"confirmed":function($event){_vm.visibleDetail = true}}}),_c('view-order-pusat-modal',{attrs:{"order-id":_vm.order.id,"visible":_vm.visibleDetail},on:{"close":function($event){return _vm.$router.push({ name: 'penjualan' })},"refresh":function($event){return _vm.$router.push({ name: 'penjualan' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }