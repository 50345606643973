<template>
  <view-order-modal
    label="Penjualan"
    size="max-w-6xl"
    with-bonus
    edit-route-name="penjualan.edit"
    :order-detail-columns="[
      'code',
      'qty',
      'weight',
      'total_price',
      'pv',
      'bv',
      'total_weight',
    ]"
    :order-id="orderId"
    :visible="visible"
    @close="$emit('close')"
    @validated="$emit('refresh')"
    @deleted="$emit('refresh')"
    @reissued="$emit('refresh')"
  >
    <template #attributes="{ classes, order, destinationOffice }">
      <div>
        <dt :class="classes.dt">No Penjualan</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.origin_code }} /
          {{ order.data.attributes.destination_code }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Dibuat Tanggal</dt>
        <dd :class="classes.dd">
          <order-date-label :order="order.data" />
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Kode Kantor</dt>
        <dd :class="classes.dd">
          {{ destinationOffice ? destinationOffice.attributes.code : '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Dibuat Oleh</dt>
        <dd :class="classes.dd">
          {{
            order.data.attributes.created_by
              ? order.data.attributes.created_by.office.name
              : '-'
          }}
          /
          {{
            order.data.attributes.created_by
              ? order.data.attributes.created_by.user.name
              : '-'
          }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Wilayah Area</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.area_name }}
          ({{ order.data.attributes.area_code }})
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Diambil/Dikirim</dt>
        <dd :class="classes.dd">
          {{
            order.data.attributes.order_shipment === 'pickup'
              ? 'Diambil'
              : 'Dikirim'
          }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Nama</dt>
        <dd :class="classes.dd">
          {{ destinationOffice ? destinationOffice.attributes.name : '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Alamat Kantor 1</dt>
        <dd :class="classes.dd">
          {{ destinationOffice ? destinationOffice.attributes.address : '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Alamat Kantor 2</dt>
        <dd :class="classes.dd">
          {{ destinationOffice ? destinationOffice.attributes.address2 : '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Diupdate Oleh</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.latest_update_by ?? '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Diupdate Pada</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.updatedAt | formatDate }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Kode Gudang/Gudang Tujuan</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.destination_office_code }} /
          {{ order.data.attributes.destination_warehouse_code }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Nomor Telpon</dt>
        <dd :class="classes.dd">
          {{ destinationOffice ? destinationOffice.attributes.phone : '-' }}
        </dd>
      </div>
      <div>
        <dt :class="classes.dt">Metode Penjualan</dt>
        <dd :class="classes.dd">
          {{
            order.data.attributes.is_using_stock
              ? 'Dengan Barang'
              : 'Tanpa Barang'
          }}
        </dd>
      </div>
      <div v-if="order.data.attributes.order_shipment === 'delivery'">
        <dt :class="classes.dt">Dikirim ke Alamat</dt>
        <dd :class="classes.dd">
          {{ order.data.attributes.shipment_address ?? '' }}
        </dd>
      </div>
    </template>
  </view-order-modal>
</template>

<script>
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import OrderDateLabel from './order-date-label.vue';

export default {
  components: {
    ViewOrderModal,
    OrderDateLabel
  },
  props: {
    orderId: String,
    visible: Boolean,
  },
  emits: ['close', 'refresh'],
};
</script>
