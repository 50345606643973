<template>
  <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
    <base-card title="Filter" :with-style="false">
      <div class="grid gap-2">
        <base-input label="Kantor Pembuat">
          <base-select
            :shadow="false"
            :options="filterOfficeCreatorOptions"
            expand
            md-expand
            v-model="filter.officeCreator"
            @change="onChangeFilterOfficeCreator"
          />
        </base-input>
        <base-input label="Destinasi Kantor">
          <base-select
            expand
            :shadow="false"
            :options="filterDestinationOfficeOptions"
            v-model="filter.destinationOffice"
          />
        </base-input>
        <base-input label="Status Faktur">
          <base-select
            expand
            :shadow="false"
            :options="filterOrderStatusOptions"
            v-model="filter.orderStatus"
          />
        </base-input>
        <base-input label="Laporan Tervalidasi">
          <base-select
            expand
            :shadow="false"
            :options="filterReportStatusOptions"
            v-model="filter.reportStatus"
          />
        </base-input>
        <period-select
          class="grid grid-cols-1 gap-2"
          with-label
          expand
          v-model="filter.periodId"
        />
        <base-input
          label="Pencarian"
          :with-label="false"
          type="text"
          search
          fullwidth
          :shadow="false"
          placeholder="Cari Kode"
          v-model="filter.code"
          debounce
        />
      </div>
      <div class="mt-4 flex gap-2">
        <base-button @click="onSave">Filter</base-button>
        <base-button color="white" @click="onReset">Reset</base-button>
      </div>
    </base-card>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import PeriodSelect from '@/components/period/period-select.vue';

export default {
  components: { PeriodSelect, BaseModal },
  props: {
    visible: Boolean,
    value: Object,
    currentPeriodId: String,
  },
  emits: ['close', 'input', 'saved'],
  computed: {
    filterDestinationOfficeOptions() {
      if (
        this.filter.officeCreator === 'kantor_pelayanan' ||
        this.filter.officeCreator === 'stockist_center'
      ) {
        return [
          {
            key: 'null',
            label: 'Semua',
            value: null,
          },
          {
            key: 'stockist',
            label: 'Stockist',
            value: 'stockist',
          },
        ];
      }

      if (this.filter.officeCreator === 'stockist') {
        return [
          {
            key: 'pusat',
            label: 'Pusat',
            value: null,
          },
          {
            key: 'kantor_pelayanan',
            label: 'Kantor Pelayanan',
            value: null,
          },
          {
            key: 'stockist_center',
            label: 'Stockist Center',
            value: null,
          },
        ];
      }

      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'stockist',
        },
        {
          key: 'kyn',
          label: 'KYN',
          value: 'kyn',
        },
        {
          key: 'scn',
          label: 'SCN',
          value: 'scn',
        },
        {
          key: 'prsh',
          label: 'PRSH',
          value: 'prsh',
        },
      ];
    },
    filterOfficeCreatorOptions() {
      if (this.filter.officeCreator === 'stockist') {
        return [
          {
            key: 'null',
            label: 'Semua',
            value: null,
          },
          {
            key: 'stockist',
            label: 'Stockist',
            value: 'stockist',
          },
        ];
      }

      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'pusat',
          label: 'Pusat',
          value: 'pusat',
        },
        {
          key: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
          value: 'kantor_pelayanan',
        },
        {
          key: 'stockist_center',
          label: 'Stockist Center',
          value: 'stockist_center',
        },
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'stockist',
        },
      ];
    },
    filterOrderStatusOptions() {
      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'valid',
          label: 'Valid',
          value: 'valid',
        },
        {
          key: 'draft',
          label: 'Draft',
          value: 'draft',
        },
        {
          key: 'cancel',
          label: 'Batal Faktur',
          value: 'cancel',
        },
        {
          key: 'hapus',
          label: 'Hapus',
          value: 'hapus',
        },
      ];
    },
    filterReportStatusOptions() {
      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'tervalidasi',
          label: 'Tervalidasi',
          value: 'tervalidasi',
        },
        {
          key: 'belum_tervalidasi',
          label: 'Belum Tervalidasi',
          value: 'belum_tervalidasi',
        },
        {
          key: 'stockist_center',
          label: 'Stockist Center',
          value: 'stockist_center',
        },
        {
          key: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
          value: 'kantor_pelayanan',
        },
      ];
    },
  },
  data() {
    return {
      filter: {
        code: null,
        destinationOffice: null,
        officeCreator: 'pusat',
        orderStatus: null,
        periodId: null,
        reportStatus: null,
      },
    };
  },
  methods: {
    onChangeFilterOfficeCreator() {
      if (
        this.filter.officeCreator !== null &&
        this.filter.officeCreator !== 'pusat'
      ) {
        this.filter.destinationOffice = null;
      }
    },
    onOpened() {
      this.filter.code = this.value.code;
      this.filter.destinationOffice = this.value.destinationOffice;
      this.filter.officeCreator = this.value.officeCreator;
      this.filter.orderStatus = this.value.orderStatus;
      this.filter.periodId = this.value.periodId;
      this.filter.reportStatus = this.value.reportStatus;
    },
    onSave() {
      this.$emit('saved', this.filter);
    },
    onReset() {
      this.filter.code = null;
      this.filter.destinationOffice = null;
      this.filter.officeCreator = 'pusat';
      this.filter.orderStatus = null;
      this.filter.periodId = this.currentPeriodId;
      this.filter.reportStatus = null;
      this.$emit('saved', this.filter);
    },
  },
};
</script>
