<template>
  <base-wrapper :loading="loading">
    <base-card title="Riwayat Transaksi">
      <template #action>
        <div class="flex gap-2">
          <base-button
            color="white"
            class="xl:hidden"
            @click="filterModalVisible = true"
          >
            <Icon icon="heroicons:funnel" />
            Filter
          </base-button>
          <base-button :to="{ name: 'penjualan.tambah' }">
            <Icon icon="heroicons:plus" class="h-4 w-4" />
            Tambah Transaksi
          </base-button>
        </div>
      </template>

      <div class="space-y-4">
        <div class="hidden flex-wrap items-end justify-end gap-4 xl:flex">
          <base-input label="Kantor Pembuat">
            <base-select
              class="w-48"
              :shadow="false"
              :options="filterOfficeCreatorOptions"
              v-model="filter.officeCreator"
              @change="onChangeFilterOfficeCreator"
            />
          </base-input>
          <base-input label="Destinasi Kantor">
            <base-select
              class="w-48"
              :shadow="false"
              :options="filterDestinationOfficeOptions"
              v-model="filter.destinationOffice"
              @change="loadSimpleOrders"
            />
          </base-input>
          <base-input label="Status Faktur">
            <base-select
              class="w-48"
              :shadow="false"
              :options="filterOrderStatusOptions"
              v-model="filter.orderStatus"
              @change="loadSimpleOrders"
            />
          </base-input>
          <base-input label="Laporan Tervalidasi">
            <base-select
              class="w-48"
              :shadow="false"
              :options="filterReportStatusOptions"
              v-model="filter.reportStatus"
              @change="loadSimpleOrders"
            />
          </base-input>
          <period-select
            class="gap-4"
            with-label
            v-model="filter.periodId"
            @change="loadSimpleOrders"
          />
          <base-input
            :with-label="false"
            type="search"
            search
            :shadow="false"
            placeholder="Cari Kode"
            v-model="filter.code"
            debounce
            @native-input="loadSimpleOrders"
          />
        </div>

        <datatable
          :total="simpleOrders.meta.page.total"
          :perPage="simpleOrders.meta.page.perPage"
          :currentPage="simpleOrders.meta.page.currentPage"
          :columns="tableColumns"
          label="faktur"
          compact
          @pagechanged="onChangePage"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="(simpleOrder, index) in simpleOrders.data"
              :key="simpleOrder.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? '' : 'bg-gray-50',
                'cursor-pointer hover:bg-green-100',
              ]"
              @click="onClickSimpleOrder(simpleOrder)"
            >
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ simpleOrder.attributes.origin_code }}
                </p>
                <order-date-label
                  :order="simpleOrder"
                  class="text-xs text-gray-500"
                />
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['origin-office'].data.id
                  ).attributes.code
                }}
                /
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['destination-office'].data.id
                  ).attributes.code
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['order'].data.id
                  ).attributes.grand_total_price | toCurrency
                }}
              </td>
              <td
                :class="[classes.td, 'text-center']"
                :title="
                  simpleOrder.attributes.order_status === 'Terlaporkan'
                    ? 'Selesai'
                    : 'Belum Selesai'
                "
              >
                <Icon
                  :icon="
                    simpleOrder.attributes.order_status === 'Terlaporkan'
                      ? 'heroicons:check-badge-solid'
                      : 'heroicons:exclamation-circle-solid'
                  "
                  :class="[
                    'mx-auto h-5 w-5',
                    simpleOrder.attributes.order_status === 'Terlaporkan'
                      ? 'text-green-500'
                      : 'text-red-500',
                  ]"
                />
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    simpleOrder.attributes.order_status === 'Draft'
                      ? 'yellow'
                      : 'blue'
                  "
                  >{{ simpleOrder.attributes.order_status }}</base-badge
                >
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(
                    simpleOrders,
                    simpleOrder.relationships['period'].data.id
                  ).attributes.start_date | formatDate('MMYY')
                }}
              </td>
            </tr>
            <tr v-if="simpleOrders.data.length" :class="classes.tr">
              <td></td>
              <td :class="classes.td">Total</td>
              <td :class="[classes.td, 'text-right']">
                {{ totalOrderPrice | toCurrency }}
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </base-card>

    <view-order-pusat-modal
      :order-id="detailModal.orderId"
      :visible="detailModal.visible"
      @close="detailModal.visible = false"
      @refresh="onRefresh"
    />

    <filter-order-pusat-modal
      :current-period-id="currentPeriodId"
      :visible="filterModalVisible"
      @close="filterModalVisible = false"
      v-model="filter"
      @saved="onFilterModalSaved"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewOrderPusatModal from '@/components/order/view-order-pusat-modal.vue';
import PeriodSelect from '@/components/period/period-select.vue';
import FilterOrderPusatModal from '@/components/order/filter-order-pusat-modal.vue';
import OrderDateLabel from '@/components/order/order-date-label.vue';

export default {
  mixins: [requestMixin],
  components: {
    ViewOrderPusatModal,
    PeriodSelect,
    FilterOrderPusatModal,
    OrderDateLabel,
  },
  computed: {
    filterDestinationOfficeOptions() {
      if (
        this.filter.officeCreator === 'kantor_pelayanan' ||
        this.filter.officeCreator === 'stockist_center'
      ) {
        return [
          {
            key: 'null',
            label: 'Semua',
            value: null,
          },
          {
            key: 'stockist',
            label: 'Stockist',
            value: 'stockist',
          },
        ];
      }

      if (this.filter.officeCreator === 'stockist') {
        return [
          {
            key: 'pusat',
            label: 'Pusat',
            value: null,
          },
          {
            key: 'kantor_pelayanan',
            label: 'Kantor Pelayanan',
            value: null,
          },
          {
            key: 'stockist_center',
            label: 'Stockist Center',
            value: null,
          },
        ];
      }

      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'stockist',
        },
        {
          key: 'kyn',
          label: 'KYN',
          value: 'kyn',
        },
        {
          key: 'scn',
          label: 'SCN',
          value: 'scn',
        },
        {
          key: 'prsh',
          label: 'PRSH',
          value: 'prsh',
        },
      ];
    },
    filterOfficeCreatorOptions() {
      if (this.filter.officeCreator === 'stockist') {
        return [
          {
            key: 'null',
            label: 'Semua',
            value: null,
          },
          {
            key: 'stockist',
            label: 'Stockist',
            value: 'stockist',
          },
        ];
      }

      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'pusat',
          label: 'Pusat',
          value: 'pusat',
        },
        {
          key: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
          value: 'kantor_pelayanan',
        },
        {
          key: 'stockist_center',
          label: 'Stockist Center',
          value: 'stockist_center',
        },
        {
          key: 'stockist',
          label: 'Stockist',
          value: 'stockist',
        },
      ];
    },
    filterOrderStatus() {
      if (this.filter.reportStatus !== null) {
        if (this.filter.reportStatus === 'belum_tervalidasi') {
          return {
            'filter[not-order_status]': 'Terlaporkan',
          };
        }

        if (this.filter.reportStatus === 'tervalidasi') {
          return {
            'filter[order_status]': 'Terlaporkan',
          };
        }

        return {
          'filter[order_status]': 'Terlaporkan',
          'filter[destination_office_category_id]':
            this.filter.reportStatus === 'stockist_center' ? 3 : 2,
        };
      }

      if (
        this.filter.orderStatus === null ||
        this.filter.orderStatus === 'hapus'
      ) {
        return null;
      }

      if (this.filter.orderStatus === 'valid') {
        return {
          'filter[not-order_status]':
            'Draft,Batal Faktur Gudang,Batal Faktur Kasir,Terhapus',
        };
      }

      if (this.filter.orderStatus === 'draft') {
        return {
          'filter[order_status]': 'Draft',
        };
      }

      return {
        'filter[order_status]': 'Batal Faktur Gudang,Batal Faktur Kasir',
      };
    },
    filterOrderStatusOptions() {
      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'valid',
          label: 'Valid',
          value: 'valid',
        },
        {
          key: 'draft',
          label: 'Draft',
          value: 'draft',
        },
        {
          key: 'cancel',
          label: 'Batal Faktur',
          value: 'cancel',
        },
        {
          key: 'hapus',
          label: 'Hapus',
          value: 'hapus',
        },
      ];
    },
    filterReportStatusOptions() {
      return [
        {
          key: 'null',
          label: 'Semua',
          value: null,
        },
        {
          key: 'tervalidasi',
          label: 'Tervalidasi',
          value: 'tervalidasi',
        },
        {
          key: 'belum_tervalidasi',
          label: 'Belum Tervalidasi',
          value: 'belum_tervalidasi',
        },
        {
          key: 'stockist_center',
          label: 'Stockist Center',
          value: 'stockist_center',
        },
        {
          key: 'kantor_pelayanan',
          label: 'Kantor Pelayanan',
          value: 'kantor_pelayanan',
        },
      ];
    },
    filterSearch() {
      if (
        this.filter.officeCreator !== null &&
        this.filter.officeCreator !== 'pusat'
      ) {
        return null;
      }

      if (
        this.filter.destinationOffice === null ||
        this.filter.destinationOffice === 'stockist'
      ) {
        return null;
      }

      return this.filter.destinationOffice;
    },
    loading() {
      return this.loadingSimpleOrders || this.loadingCurentPeriod;
    },
    tableColumns() {
      return [
        {
          id: 'code',
          name: 'Nomor Faktur',
        },
        {
          id: 'office_code',
          name: 'Kode Stockist/Kode Gudang',
        },
        {
          id: 'total_price',
          name: 'Total Harga',
          theadClass: 'text-right',
        },
        {
          id: 'report_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
        {
          id: 'order_status',
          name: 'Status Faktur',
          theadClass: 'text-center',
        },
        {
          id: 'period',
          name: 'PRD',
        },
      ];
    },
  },
  data() {
    return {
      currentPeriodId: null,
      detailModal: {
        orderId: null,
        visible: false,
      },
      filter: {
        code: null,
        destinationOffice: null,
        officeCreator: 'pusat',
        orderStatus: null,
        periodId: null,
        reportStatus: null,
      },
      filterModalVisible: false,
      loadingCurentPeriod: false,
      loadingSimpleOrders: false,
      simpleOrders: {
        data: [],
        meta: {
          page: {},
        },
      },
      totalOrderPrice: 0,
    };
  },
  methods: {
    async loadCurrentPeriod() {
      this.loadingCurentPeriod = true;

      const [res, err] = await this.request(
        '/api/v1/periods/-actions/get-current-period'
      );

      if (!err) {
        this.filter.periodId = res.data.id;
        this.currentPeriodId = res.data.id;
      }

      this.loadingCurentPeriod = false;
    },
    async loadSimpleOrders(params) {
      this.loadingSimpleOrders = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          include: 'destination-office,origin-office,order,period',
          'fields[simple-orders]':
            'origin_code,orderedAt,createdAt,destination-office,origin-office,order,order_status,period',
          'fields[offices]': 'code',
          'fields[orders]': 'grand_total_price',
          'fields[periods]': 'start_date',
          'page[size]': 5,
          'filter[origin_office_category_id]': 1,
          'filter[destination_office_category_id]': 4,
          sort: '-id',
          'filter[origin_code]': this.filter.code,
          'filter[office_type_creator]': this.filter.officeCreator,
          'filter[search]': this.filterSearch,
          'filter[trashed]': this.filter.orderStatus === 'hapus',
          'filter[period_uuid]': this.filter.periodId,
          'filter[with_summary]': true,
          ...this.filterOrderStatus,
          ...params,
        },
      });

      if (!err) {
        this.simpleOrders = res;

        if (res.meta.total_price) {
          this.totalOrderPrice = res.meta.total_price;
        }
      }

      this.loadingSimpleOrders = false;
    },
    onChangeFilterOfficeCreator() {
      if (
        this.filter.officeCreator !== null &&
        this.filter.officeCreator !== 'pusat'
      ) {
        this.filter.destinationOffice = null;
      }

      this.loadSimpleOrders();
    },
    onChangePage(page) {
      this.loadSimpleOrders({
        'page[number]': page,
        'filter[with_summary]': false,
      });
    },
    onClickSimpleOrder(simpleOrder) {
      this.detailModal.visible = true;
      this.detailModal.orderId = simpleOrder.relationships.order.data.id;
    },
    onRefresh() {
      this.detailModal.visible = false;
      this.detailModal.orderId = null;

      this.loadSimpleOrders();
    },
    onFilterModalSaved(value) {
      this.filter.code = value.code;
      this.filter.destinationOffice = value.destinationOffice;
      this.filter.officeCreator = value.officeCreator;
      this.filter.orderStatus = value.orderStatus;
      this.filter.periodId = value.periodId;
      this.filter.reportStatus = value.reportStatus;

      this.filterModalVisible = false;

      this.loadSimpleOrders();
    },
  },
  async created() {
    await this.loadCurrentPeriod();

    this.loadSimpleOrders();
  },
};
</script>
