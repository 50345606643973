<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <create-order-form
        with-destination-area
        restock-type-label="Metode Penjualan"
        :with-loan="false"
        with-order-balance
        :disabled-date="false"
        v-model="order"
      />

      <cashback-order-provider
        v-if="order.destinationOffice.id"
        class="space-y-4"
        office-type="pusat"
        :total-price="totalPrice"
        :area-code="order.area.code"
        :shipment-type="order.shipment_type"
        v-model="cashback"
        v-slot="{ minOrder: minCashback }"
      >
        <create-order-detail-form
          :order-id="order.id"
          :origin-office="{ id: me.office_id, code: me.office_code }"
          :origin-warehouse="order.originWarehouse"
          :destination-office="order.destinationOffice"
          :buyer-type="order.buyerType"
          :area="order.area"
          :min-spend="minOrderSpend"
          with-cashback-warning
          :min-cashback="minCashback"
          :columns="[
            'code',
            'name',
            'point',
            'stock',
            'qty',
            'price',
            'total_price',
            'action',
            'total_weight',
            'point_value',
            'bonus_value',
          ]"
          total-price-label=""
          :create-order-attributes="{
            is_using_stock: order.using_stock,
            order_shipment: order.shipment_type,
            order_type: 'sale',
            destination_warehouse_id:
              order.destinationOffice.currentWarehouse.id,
            ...(order.custom_shipment_address
              ? {
                  destination_address: {
                    province_id: order.shipmentAddress.province.id,
                    city_id: order.shipmentAddress.city.id,
                    district_id: order.shipmentAddress.district.id,
                    village_id: order.shipmentAddress.village.id,
                    address: order.shipmentAddress.address,
                  },
                }
              : {}),
          }"
          v-model="orderDetails"
          @order-created="onOrderCreated"
        />

        <template v-if="totalPrice > 0">
          <base-card>
            <div class="grid grid-cols-2 items-start gap-4">
              <div class="grid gap-4">
                <base-input
                  inset
                  disabled
                  label="Total Barang"
                  :value="totalQty | toCurrency"
                />
                <base-input
                  inset
                  disabled
                  label="Cashback"
                  :value="cashback | toCurrency"
                />
              </div>
              <div class="grid gap-4">
                <base-input
                  inset
                  disabled
                  label="DPP"
                  :value="dpp | toCurrency"
                />
                <base-input
                  inset
                  disabled
                  label="PPN (%)"
                  :value="ppn | toCurrency"
                />
                <base-input
                  inset
                  disabled
                  label="Total Belanja"
                  :value="totalPrice | toCurrency"
                />
                <base-input
                  inset
                  disabled
                  :label="
                    paymentRemainder > 0 ? 'Total Kekurangan' : 'Total Kelebihan'
                  "
                  :value="Math.abs(paymentRemainder) | toCurrency"
                />
              </div>
            </div>
          </base-card>

          <create-payment-form
            v-model="paymentMethods"
            :order-id="order.id"
            :payment-id="paymentId"
            auto-save
            sync
            :filter-reserved="false"
            :filter-payment-method-type="{
              'filter[not-name]': 'Cashback'
            }"
            :stockist-balance="order.destinationOffice.order_balance"
            :with-validate-button="false"
            :with-summary="false"
          />
        </template>

        <div class="flex justify-end gap-2">
          <base-button
            :disabled="totalPrice < minOrderSpend"
            @click="visibleValidate = true"
            >Siap Validasi</base-button
          >
          <base-button color="danger" :to="{ name: 'penjualan' }"
            >Selesai</base-button
          >
        </div>
      </cashback-order-provider>
    </div>

    <validate-order-confirmation
      :order-id="order.id"
      :visible="visibleValidate"
      @close="visibleValidate = false"
      @confirmed="visibleDetail = true"
    />
    <view-order-pusat-modal
      :order-id="order.id"
      :visible="visibleDetail"
      @close="$router.push({ name: 'penjualan' })"
      @refresh="$router.push({ name: 'penjualan' })"
    />
  </base-wrapper>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import CashbackOrderProvider from '@/components/order/cashback-order-provider.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';
import ViewOrderPusatModal from '@/components/order/view-order-pusat-modal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import { toCurrency } from '@/services/currency.service';
import dayjs from 'dayjs';

export default {
  mixins: [requestMixin],
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    CashbackOrderProvider,
    CreatePaymentForm,
    ValidateOrderConfirmation,
    ViewOrderPusatModal,
  },
  data() {
    return {
      cashback: null,
      loadingBuyerType: false,
      loadingDefaultOriginWarehouse: false,
      loadingOrder: false,
      loadingSetup: false,
      loadingPaymentId: false,
      minOrderSpend: 0,
      order: {
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        custom_shipment_address: false,
        date: new Date(),
        destinationOfficeType: 'stockist',
        destinationOffice: {
          address: null,
          area: {
            id: null,
            code: null,
          },
          code: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          id: null,
          name: null,
          originalCode: null,
          order_balance: null,
          searchCode: null,
        },
        id: null,
        originWarehouse: {
          area: {
            id: null,
            code: null,
          },
          code: null,
          id: null,
          name: null,
          originalCode: null,
          searchCode: null,
        },
        shipment_type: 'pickup',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
        using_stock: true,
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          isPoint: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
          totalWeight: null,
          pointValue: null,
          bonusValue: null,
        },
      ],
      paymentId: null,
      paymentMethods: [
        {
          paymentMethodId: null,
          paymentMethodTypeId: null,
          amount: null,
          officeBankId: null,
          receiptNumber: null,
        },
      ],
      visibleValidate: false,
      visibleDetail: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return (
        this.loadingBuyerType ||
        this.loadingDefaultOriginWarehouse ||
        this.loadingOrder ||
        this.loadingSetup ||
        this.loadingPaymentId
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
    totalQty() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + Number(orderDetail.qty),
        0
      );
    },
    totalPayment() {
      return this.paymentMethods
        .filter((paymentMethod) => {
          return (
            paymentMethod.amount &&
            !isNaN(Number(paymentMethod.amount.replace(/\D/gi, '')))
          );
        })
        .reduce(
          (total, paymentMethod) =>
            total + Number(paymentMethod.amount.replace(/\D/gi, '')),
          0
        );
    },
    dpp() {
      return (100 / 111) * this.totalPrice;
    },
    ppn() {
      return (11 / 100) * this.dpp;
    },
    paymentRemainder() {
      return this.totalPrice - this.cashback - this.totalPayment;
    },
  },
  methods: {
    dayjs,
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, err] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 10,
          'fields[buyer-types]': 'code',
        },
      });

      if (!err && res.data.length) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadDefaultOriginWarehouse() {
      this.loadingDefaultOriginWarehouse = true;

      const [warehouse, err] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}`,
        {
          params: {
            include: 'area',
            'fields[warehouses]': 'code,name,area',
            'fields[areas]': 'code',
          },
        }
      );

      if (!err) {
        this.order.originWarehouse.id = warehouse.data.id;
        this.order.originWarehouse.code = warehouse.data.attributes.code;
        this.order.originWarehouse.name = warehouse.data.attributes.name;

        const area = this.getSingleIncluded(
          warehouse,
          warehouse.data.relationships.area.data.id
        );

        this.order.originWarehouse.area.id = area.id;
        this.order.originWarehouse.area.code = area.attributes.code;

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.originWarehouse.searchCode = `${warehouse.data.attributes.code} (${area.attributes.code})`;
        this.order.originWarehouse.originalCode = `${warehouse.data.attributes.code} (${area.attributes.code})`;
      }

      this.loadingDefaultOriginWarehouse = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      const [order, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'area,buyer-type,destination-warehouse,destination-office,origin-office,origin-warehouse,order-details',
            'fields[orders]':
              'orderedAt,order_shipment,is_valid_for_payment,area,buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details,shipment_address,final_price',
            'fields[areas]': 'code',
            'fields[offices]':
              'code,name,address,office_type,area_code,order_balance_available',
            'fields[warehouses]': 'code,name',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price,is_point,total_weight,point_value,bonus_value',
          },
        }
      );

      if (!err) {
        this.order.id = order.data.id;

        const area = this.getSingleIncluded(
          order,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          order,
          order.data.relationships['buyer-type'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          order,
          order.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['destination-warehouse'].data.id
        );
        const originOffice = this.getSingleIncluded(
          order,
          order.data.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['origin-warehouse'].data.id
        );

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        this.order.custom_shipment_address = false;
        this.order.shipmentAddress.address =
          destinationOffice.attributes.address ||
          order.data.attributes.shipment_address;
        this.order.date = order.data.attributes.orderedAt;

        this.order.destinationOffice.id = destinationOffice.id;
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.originalCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.name = destinationOffice.attributes.name;
        this.order.destinationOffice.order_balance = destinationOffice.attributes.order_balance_available;

        this.order.destinationOffice.address =
          destinationOffice.attributes.address;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        this.order.originWarehouse.area.id = null;
        this.order.originWarehouse.area.code =
          originOffice.attributes.area_code;

        this.order.originWarehouse.code = originWarehouse.attributes.code;
        this.order.originWarehouse.id = originWarehouse.id;
        this.order.originWarehouse.name = originWarehouse.attributes.name;
        this.order.originWarehouse.originalCode =
          originWarehouse.attributes.code;
        this.order.originWarehouse.searchCode = originWarehouse.attributes.code;

        this.order.shipment_type = order.data.attributes.order_shipment;

        this.order.valid = order.data.attributes.is_valid_for_payment;

        const orderDetails = this.getIncludedByType(order, 'order-details');

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          isPoint: orderDetail.attributes.is_point,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
          totalWeight: orderDetail.attributes.total_weight,
          pointValue: orderDetail.attributes.point_value,
          bonusValue: orderDetail.attributes.bonus_value,
        }));

        this.orderDetails.push({
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          isPoint: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
          totalWeight: null,
          pointValue: null,
          bonusValue: null,
        });
      }

      this.loadingOrder = false;
    },
    async loadPaymentId(id) {
      this.loadingPaymentId = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${id || this.$route.params.id}/payments`,
        {
          params: {
            'fields[payments]': 'code',
            'page[size]': 1,
          },
        }
      );

      if (!err && res.data.length) {
        this.paymentId = res.data[0].id;

        await this.loadPaymentMethods();
      }

      this.loadingPaymentId = false;
    },
    async loadPaymentMethods() {
      const [res, err] = await this.request(
        `/api/v1/payments/${this.paymentId}/payment-methods`,
        {
          params: {
            include: 'payment-method-type,office-bank',
            'fields[payment-methods]':
              'payment_amount,receipt_number,payment-method-type,office-bank,payment_method_type',
            'fields[payment-method-types]': 'name',
            'fields[office-banks]': 'bank_name',
          },
        }
      );

      if (!err) {
        const paymentMethods = res.data.filter(
          (paymentMethod) =>
            paymentMethod.attributes.payment_method_type !== 'Cashback'
        );

        if (paymentMethods.length) {
          this.paymentMethods = paymentMethods.map((paymentMethod) => {
            const paymentMethodType =
              paymentMethod.relationships['payment-method-type'].data;
            const officeBank = paymentMethod.relationships['office-bank'].data;

            return {
              paymentMethodId: paymentMethod.id,
              paymentMethodTypeId: paymentMethodType
                ? paymentMethodType.id
                : null,
              amount: toCurrency(paymentMethod.attributes.payment_amount),
              officeBankId: officeBank ? officeBank.id : null,
              receiptNumber: paymentMethod.attributes.receipt_number,
            };
          });

          const orderBalanceUsed = paymentMethods.find(paymentMethod => paymentMethod.attributes.payment_method_type === 'Saldo Stockist')

          if (orderBalanceUsed) {
            this.order.destinationOffice.order_balance += orderBalanceUsed.attributes.payment_amount
          }
        }
      }
    },
    async loadSetup() {
      this.loadingSetup = true;

      const [res, err] = await this.request('/api/v1/setups', {
        params: {
          'filter[setup_key]': 'min_order_spend_for_stockist',
          'fields[setups]': 'setup_value',
        },
      });

      if (!err && res.data.length) {
        this.minOrderSpend = +res.data[0].attributes.setup_value;
      }

      this.loadingSetup = false;
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.loadPaymentId(order.data.id);

      this.$router.push({
        name: 'penjualan.edit',
        params: {
          id: order.data.id,
        },
      });
    },
  },
  created() {
    this.loadSetup();

    if (this.$route.params.id) {
      this.loadOrder();
      this.loadPaymentId();
    } else {
      this.loadBuyerType();
      this.loadDefaultOriginWarehouse();
    }
  },
};
</script>
